import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import notfound from "../assets/img/arkaplan-hata2.png"


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    
  

    <div className="acquire-bottom__left hata">
      <div className="acquire-bottom__left-img hata">
        <img className="" style={{marginLeft: -41}}
          alt="alt text"
          src={notfound}
        />
      </div>
      <div className="acquire-bottom__left-content ortala">
        <h1 className="hata-title">  404</h1>
        <span className="hata-text">Ters Giden Bi' Şeyler Var</span>
        <br />
        <a href="/" target="_blank"> <button class="red-button green">Ana Sayfaya Dön</button></a>
        <br />
        <a href="/basari-hikayeleri/" target="_blank"> <button class="red-button pink">Başarı Hikayelerimizi Keşfet</button></a>
      </div>
    </div>
   



    
  </Layout>
)

export default NotFoundPage
